<template>
  <v-menu
    v-if="item.status"
    offset-y
    :close-on-content-click="false"
    @input="onMenuInput"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-if="item.status && item.eclient_request_id "
        text
        class="elevation-0"
        :class="{
          'px-0':mobile
        }"
        v-bind="attrs"
        v-on="on"
        @click="getStatusList(item)"
      >
        <v-icon v-if="item.user_id === userId">
          mdi-chevron-down
        </v-icon>
        {{ item.status }}
      </v-btn>
    </template>
    <v-simple-table
      v-if="statusList.length"
      class="pt-2"
    >
      <tbody>
        <tr
          v-for="(element, idx) in statusList"
          :key="'status-item-'+idx"
        >
          <td>{{ element.status }}</td>
          <td>{{ element.date_of }}</td>
          <td>
            <v-menu
              offset-overflow
              :nudge-left="nudgeLeft"
              :nudge-top="nudgeTop"
              :max-width="width"
              :close-on-content-click="false"
              max-height="400px"
            >
              <template #activator="{ on: on2, attrs:attrs2 }">
                <v-btn
                  plain
                  icon
                  v-bind="attrs2"
                  :disabled="!element.is_client_available"
                  color="primary"
                  v-on="on2"
                  @click="getStatusMessage(element)"
                >
                  <v-icon>
                    mdi-comment
                  </v-icon>
                </v-btn>
                <v-btn
                  plain
                  icon
                  large
                  v-bind="attrs2"
                  :disabled="!element.is_client_available"
                  v-on="on2"
                  @click="getStatusMessageFile(element)"
                >
                  <v-icon>
                    mdi-download
                  </v-icon>
                </v-btn>
              </template>
              <v-card
                v-if="messageType === 'string'"
                height="350px"
                class="overflow-y-auto"
              >
                <v-card-text v-html="statusMessage" />
              </v-card>
              <v-list
                v-else-if="messageType === 2"
                :width="listWidth"
              >
                <v-list-item>
                  <span class="mx-2 font-weight-bold">Код: </span>
                  {{ statusMessage.reasonCode }}
                </v-list-item>
                <v-list-item>
                  <p class="mx-2 mb-2 status-description">
                    {{ statusMessage.description }}
                  </p>
                </v-list-item>
                <template
                  v-if="'entries' in statusMessage && Array.isArray(statusMessage.entries) && statusMessage.entries.length"
                >
                  <v-list-item
                    v-for="(error, index) in statusMessage.entries"
                    :key="`err-${index}`"
                  >
                    <p class="mx-2">
                      {{ error.text }} <b v-if="error.wareNumber">Товары:</b> {{ error.wareNumber }}
                    </p>
                  </v-list-item>
                </template>
              </v-list>
              <v-list
                v-else-if="messageType === 15"
                :width="listWidth"
              >
                <v-list-item>
                  <span class="mx-2 font-weight-bold">Причина: </span>
                  {{ statusMessage.reason }}
                </v-list-item>
                <v-simple-table
                  class="mx-5 mb-2"
                  height="300px"
                  fixed-header
                >
                  <template #default>
                    <thead class="text-left">
                      <th class="px-4">
                        Поле
                      </th>
                      <th class="px-4">
                        Раздел
                      </th>
                      <th class="px-4">
                        Ошибка
                      </th>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(en, entryIndex) in statusMessage.entries"
                        :key="'entries-'+entryIndex"
                      >
                        <td>
                          <v-chip
                            v-if="'wareNumber' in en"
                            small
                            color="secondary"
                            class="mt-1"
                          >
                            Товар №{{ en.wareNumber }}
                          </v-chip>
                          {{ en.field }}
                        </td>
                        <td>
                          {{ en.section }}
                        </td>
                        <td>
                          {{ en.text }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-list>
              <v-list v-else-if="messageType === 3">
                <v-list-item>
                  <span class="mx-2 font-weight-bold">
                    Регистрационный номер "A":
                  </span>
                </v-list-item>
                <v-list-item>
                  <p class="mx-2 mb-2 status-description">
                    {{ statusMessage.regNumberA }}
                  </p>
                </v-list-item>
              </v-list>
              <v-list v-else-if="messageType === 5">
                <v-list-item>
                  <span class="mx-2 font-weight-bold">
                    Регистрационный номер "B":
                  </span>
                </v-list-item>
                <v-list-item>
                  <p class="mx-2 mb-2 status-description">
                    {{ statusMessage.regNumberB }}
                  </p>
                </v-list-item>
              </v-list>
              <v-list v-else-if="messageType === 9">
                <v-list-item>
                  <span class="mx-2 font-weight-bold">
                    {{ statusMessage.reason }}
                  </span>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-menu>
</template>

<script>
import moment from 'moment'
import {capitalize} from '@/helpers/text'
import { mapGetters } from 'vuex'
import { downloadXml, getFilenameFromContentDisposition } from "@/helpers/documents";
export default {
  props:{
    item:{
      required:true,
      type:Object
    }
  },
  data:() => ({
    statusList:[],
    statusMessage:{},
  }),
  computed:{
    ...mapGetters({
      user:'auth/getUser'
    }),
    userId(){
      return this.user?.user?.id
    }, 
    mobile(){
      return this.$vuetify.breakpoint.mobile
    },
    nudgeLeft(){
      return '500px'
    },
    nudgeTop(){
      if(this.mobile){
        return '200px'
      }
      if([3,5].includes(this.messageType)) return '100px';
      return [15].includes(this.messageType) ? '300px':'200px'
    },
    listWidth(){
      if(this.mobile) return '95vw';
      return 'auto'
    },
    width(){
      if(this.mobile){
        return '100vw'
      }
      return [15].includes(this.messageType) ? '700px':'500px'
    },
    messageType(){
      if(this.statusMessage instanceof Object){
        if('reasonCode' in this.statusMessage && 'description' in this.statusMessage){
          return 2
        }else if('reason' in this.statusMessage && 'entries' in this.statusMessage){
          return 15
        }else if('regNumberA' in this.statusMessage){
          return 3
        }else if('regNumberB' in this.statusMessage){
          return 5
        }else{
          return 9
        }
      }else if(typeof this.statusMessage === 'string'){
        return 'string'
      }
      return null 
    },
  },
  methods:{
    getStatusList(item){
      const {eclient_request_id, user_id} = item
      if(user_id !== this.userId) return false
      this.$store.dispatch('journal/getStatusList', eclient_request_id).then((res) => {
        const doc_items = res.data || []
        this.statusList = doc_items.map(i => {
          const {st_name, f_dateof, ...rest} = i
          const [first,...words] = st_name.split(" ") 
          return {
            status:[capitalize(first) , ...words].join(" "),
            date_of:moment(f_dateof).format('DD.MM.YYYY HH:mm:ss'),
            ...rest
          }
        })
      }).catch(err => {
        this.$snackbar({text:err.response.data, color:"red", top:false, right: false});
      })
    },
    getStatusMessage(item){
      this.$store.dispatch('journal/getStatusMessage', item).then(res => {
        this.statusMessage = res.data
      }).catch(err => {
        this.$snackbar({text:err.response.data, color:"red", top:false, right: false});
      })
    },
    getStatusMessageFile(item){
      this.$store.dispatch('journal/getStatusMessageFile', item).then((res) => {
        downloadXml(res.data, getFilenameFromContentDisposition(res));
      })
    },
    onMenuInput(value){
      if(value === false){
        this.statusList = [];
        this.statusMessage = ""
      } 

    },
  }
}
</script>
<style scoped>
  .entries-container{
      max-height: 100px;
  }
  .status-description{
    word-break: break-word;
  }
</style>